import React, { Fragment, useEffect, useState } from 'react';
import PDF from 'react-pdf-js-infinite';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Grid, Typography, FormControlLabel, FormGroup, Checkbox, Button } from '@mui/material';
import { Remove, Add } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { fetchFile } from '../../../reducers/entities';

const buttonStyle = {
  margin: '0 10px',
  fontSize: '36px',
  border: '1px solid #f7f6f6',
  padding: 5,
};

const ZoomButtons = ({ onZoom, zoom, disabled }) => (
  <div style={{ position: 'absolute', right: '20px', bottom: '20px', zIndex: 2 }}>
    <Button
      disabled={disabled || zoom <= 0.5}
      style={buttonStyle}
      onClick={() => onZoom(zoom - 0.5)}
      variant="fab"
    >
      <Remove />
    </Button>
    <Button
      disabled={disabled || zoom >= 3}
      style={buttonStyle}
      onClick={() => onZoom(zoom + 0.5)}
      variant="fab"
    >
      <Add />
    </Button>
  </div>
);

ZoomButtons.propTypes = {
  zoom: PropTypes.number.isRequired,
  onZoom: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const ValidationStep = ({ cancellationId, setStep }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [file, setFile] = useState();

  useEffect(() => {
    if (!file) {
      const getCertificateFile = async () => {
        const { data } = await dispatch(fetchFile(`${cancellationId}/certificate`));
        setFile(data);
      };
      getCertificateFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [zoom, setZoom] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  return (
    <Grid container spacing={1} direction="column" style={{ height: '100%' }}>
      <Grid item style={{ paddingBottom: 8 }}>
        <Typography variant="h5" align="center">
          <FormattedMessage id="cancellation-certificate.title" />
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent="center"
        style={{
          flex: 1,
          borderBottom: '1px solid #f7f6f6',
          borderTop: '1px solid #f7f6f6',
          position: 'relative',
          backgroundColor: '#fff',
        }}
      >
        {file && (
          <Fragment>
            <ZoomButtons zoom={zoom} disabled={isLoading} onZoom={setZoom} />
            <div style={{ overflow: 'auto', height: '100%', width: '100%', position: 'absolute' }}>
              <PDF
                file={file}
                key={zoom}
                scale={zoom}
                onDocumentComplete={() => setIsLoading(false)}
              />
            </div>
          </Fragment>
        )}
      </Grid>

      <Grid item>
        <FormGroup row>
          <FormControlLabel
            control={<Checkbox color="primary" />}
            checked={checkboxChecked}
            onChange={event => setCheckboxChecked(event.target.checked)}
            label={intl.formatMessage({ id: 'cancellation-certificate.confirm-valid' })}
          />
        </FormGroup>
      </Grid>

      <Grid container wrap="wrap" spacing={1} justifyContent="flex-end">
        <Grid item>
          <Button
            color="primary"
            disabled={!checkboxChecked}
            onClick={() => setStep(1)}
            variant="contained"
          >
            <FormattedMessage id="global.continue" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

ValidationStep.propTypes = {
  cancellationId: PropTypes.string.isRequired,
  setStep: PropTypes.func.isRequired,
};

export default ValidationStep;
