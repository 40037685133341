import { compose } from 'recompose';
import withIntl from '../../withIntl';
import withErrorBoundary from '../../withErrorBoundary';
import withRedux from '../../withRedux';
import withRouter from '../../withRouter';
import withCustomThemeProvider from '../../withCustomThemeProvider';
import withMessageLogger from './withMessageLogger';
import App from './App';
import withGrootConfig from '../../withGrootConfig';
import withLocalizedDate from '../../withLocalizedDate';

export const withRoot = compose(
  withErrorBoundary,
  withRedux,
  withGrootConfig,
  withIntl,
  withCustomThemeProvider,
  withLocalizedDate,
  withRouter,
  withMessageLogger
);

export default withRoot(App);
