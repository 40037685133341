import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Step, StepButton, Stepper } from '@mui/material';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import SignatureStep from './SignatureStep';
import LogoLarge from '../../../components/LogoLarge';
import PoweredBy from '../SaleContract/SaleContractLayout/PoweredBy';
import { fetchEntity } from '../../../reducers/entities';
import ValidationStep from './ValidationStep';

const CancellationCertificate = ({ cancellationId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [cancellation, setCancellation] = useState();

  useEffect(() => {
    if (!cancellation) {
      const getCancellation = async () => {
        const { response } = await dispatch(fetchEntity(cancellationId));
        setCancellation(response.data);
      };
      getCancellation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const steps = ['cancellation-certificate.steps-view', 'cancellation-certificate.steps-sign'];
  const [activeStep, setActiveStep] = useState(0);

  const renderSteps = () => {
    switch (steps[activeStep]) {
      case 'cancellation-certificate.steps-view':
        return <ValidationStep cancellationId={cancellationId} setStep={setActiveStep} />;
      case 'cancellation-certificate.steps-sign':
        return <SignatureStep />;
      default:
        return null;
    }
  };

  return (
    <Grid
      container
      direction="column"
      style={{ height: '100vh', paddingTop: 20, paddingBottom: 20 }}
    >
      <Grid
        container
        justifyContent="center"
        style={{ backgroundColor: '#fff', borderBottom: '1px solid #f7f6f6', paddingBottom: 5 }}
      >
        <Stepper style={{ width: '90%' }} activeStep={activeStep}>
          {steps.map((step, index) => (
            <Step key={step}>
              <StepButton color="primary" onClick={() => setActiveStep(index)}>
                {intl.formatMessage({ id: step })}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid container style={{ flex: 1 }}>
        <Grid item xs={3} style={{ background: '#fff' }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            style={{ height: '100%', position: 'relative' }}
            alignItems="center"
          >
            <Grid container alignItems="center" justifyContent="center" style={{ flex: 1 }}>
              <LogoLarge dimension={180} />
            </Grid>
            <PoweredBy positionStyle={{ marginBottom: '14px' }} />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={9}
          style={{ height: '100%', width: '100%', padding: '20px', backgroundColor: '#f7f6f6' }}
        >
          {renderSteps()}
        </Grid>
      </Grid>
    </Grid>
  );
};

CancellationCertificate.propTypes = {
  cancellationId: PropTypes.string.isRequired,
};
export default CancellationCertificate;
