import { compose, lifecycle } from 'recompose';
import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid } from '@mui/material';
import { connect } from 'react-redux';
import { mix } from 'polished';
// eslint-disable-next-line no-restricted-imports
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { createTheme, ThemeProvider as MUIThemeProviderV5 } from '@mui/material/styles';
import customThemeConstant from './constants/customTheme';
import { triggerFetchCustomTheme } from './reducers/customTheme';
import { getIsLoading, getTheme } from './selectors/customTheme';

function getLighterColor(color) {
  return mix(0.25, color, '#FFF');
}
function getDarkerColor(color) {
  return mix(0.4, color, '#000');
}

// eslint-disable-next-line no-console
const baseConsoleError = console.error;
// eslint-disable-next-line no-console
console.error = (message, ...optionalParams) => {
  if (typeof message === 'string' && message.startsWith('Material-UI: The contrast ratio of')) {
    return;
  }

  baseConsoleError(message, ...optionalParams);
};

const PRIMARY = '#086E8E';
const SECONDARY = '#086E8E';
const ERROR = '#ff8468';
const WARNING = '#ffc655';
const SUCCESS = '#66dcaa';

const mapStateToProps = state => ({
  isLoading: getIsLoading(state),
  theme: getTheme(state),
});

const actionCreators = {
  fetchCustomTheme: triggerFetchCustomTheme,
};

function componentDidMount() {
  this.props.fetchCustomTheme();
}

const Loader = () => (
  <Grid container alignItems="center" justifyContent="center" style={{ height: '100vh' }}>
    <Grid item>
      <CircularProgress style={{ color: customThemeConstant.primary }} size={60} />
    </Grid>
  </Grid>
);

const withCustomThemeProvider = WrappedComponent => {
  const withCustomThemed = ({ isLoading, theme: customizedTheme, ...props }) => {
    const {
      palette: {
        primary: { main: mainPrimary },
        secondary: { main: mainSecondary },
      },
    } = customizedTheme;

    const theme = {
      palette: {
        tonalOffset: 0.3,
        background: {
          default: '#fafafa',
        },
        common: {
          black: '#303030',
        },
        primary: {
          main: mainPrimary || PRIMARY,
        },
        secondary: {
          main: mainSecondary || mainPrimary || SECONDARY,
        },
        error: {
          main: ERROR,
        },
        warning: {
          main: WARNING,
        },
        success: {
          main: SUCCESS,
        },
        text: {
          disabled: '#989898',
          dark: '#303030',
          greyBase: '#646464',
          white: '#ffffff',
        },
        contrastThreshold: 2.5,
        getLighterColor,
        getDarkerColor,
      },
      typography: {
        fontWeightSemiBold: 600,
        fontWeightBold: 700,
        h1: {
          lineHeight: 'normal',
          fontWeight: 500,
        },
        h2: {
          lineHeight: 'normal',
          fontWeight: 500,
        },
        h3: {
          lineHeight: 'normal',
          fontWeight: 500,
        },
        h4: {
          lineHeight: 'normal',
          fontWeight: 500,
        },
        h5: {
          lineHeight: 'normal',
          fontWeight: 500,
        },
        h6: {
          lineHeight: 'normal',
          fontWeight: 500,
        },
        body1: {
          lineHeight: 1.3,
        },
        body2: {
          lineHeight: 1.3,
        },
        caption: {
          lineHeight: 'normal',
        },
        button: {
          textTransform: 'uppercase',
        },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 320,
          md: 768,
          lg: 1280,
        },
      },
      spacing: 8,
      overrides: {
        MuiStepper: {
          root: {
            background: 'none',
          },
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 320,
            md: 768,
            lg: 1280,
          },
        },
        spacing: 8,
        MuiListSubheader: {
          root: {
            backgroundColor: 'transparent',
          },
        },
        MuiStepLabel: {
          // Name of the component ⚛️ / style sheet
          label: {
            // Name of the rule
            fontSize: '16px',
            fontWeight: '700!important',
          },
        },
        MuiFilledInput: {
          root: {
            height: '60px',
          },
        },
        MuiBadge: {
          badge: {
            backgroundColor: '#fff',
            color: 'primary',
          },
        },
        MuiSvgIcon: {
          root: {
            verticalAlign: 'text-bottom',
          },
        },
        MuiListItem: {
          root: {
            color: 'black',
          },
        },
        MuiTextField: {
          root: {
            variant: 'filled',
          },
        },
      },
      props: {
        MuiTextField: {
          variant: 'filled',
          fullWidth: true,
        },
        MuiSelect: {
          variant: 'filled',
          fullWidth: true,
        },
        MuiFormControl: {
          variant: 'filled',
          fullWidth: true,
        },
      },
    };

    const muiTheme = createTheme(theme);

    return isLoading ? (
      <Loader />
    ) : (
      <SCThemeProvider theme={muiTheme}>
        <MUIThemeProviderV5 theme={muiTheme}>
          <WrappedComponent {...props} />
        </MUIThemeProviderV5>
      </SCThemeProvider>
    );
  };

  withCustomThemed.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    theme: PropTypes.shape({
      properties: PropTypes.shape({ primary: PropTypes.string, secondary: PropTypes.string }),
    }).isRequired,
  };

  return compose(
    connect(mapStateToProps, actionCreators),
    lifecycle({ componentDidMount })
  )(withCustomThemed);
};

export default withCustomThemeProvider;
